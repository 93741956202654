import React from "react";
import { Routes, Route } from "react-router-dom";
import About from "./pages/About";
import Home from "./pages/Home";
import Clients from "./pages/Clients";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Faq from "./pages/Faq";
import Services from "./pages/Services";
import Consultation from "./pages/Consultation";
import ProjectMgt from "./pages/services/ProjectMgt";
import Engineering from "./pages/services/Engineering";
import StaffingSupport from "./pages/services/StaffingSupport";
import FieldServices from "./pages/services/FieldServices";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/clients" element={<Clients />} />
      <Route path="/consultation" element={<Consultation />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/engineering" element={<Engineering />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/field-services" element={<FieldServices />} />
      <Route path="/project-management" element={<ProjectMgt />} />
      <Route path="/services" element={<Services />} />
      <Route path="/staffing-support" element={<StaffingSupport />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
