import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo-dark.png";
import logoWhite from "../assets/logo.png";
import Footer from "../components/Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Services = () => {
  const [sidemenuOpen, setSideMenuOpen] = useState(false);
  const [mobilemenuOpen, setMobileMenuOpen] = useState(false);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [fieldServicesDropdownOpen, setFieldServicesDropdownOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const inputChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const toggleSideMenu = () => {
    setSideMenuOpen(!sidemenuOpen);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobilemenuOpen);
  };

  const toggleServiceDropdown = () => {
    setServiceDropdownOpen(!serviceDropdownOpen);
  };

  const toggleFieldServicesDropdown = () => {
    setFieldServicesDropdownOpen(!fieldServicesDropdownOpen);
  };

  const sendMessage = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      await axios.post(
        "https://michaelsandassociates.onrender.com/api/message",
        {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
        }
      );

      toast.success("Message sent successfully!");
      setLoading(false);
      setFormData((prevState) => ({
        ...prevState,
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      }));
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <div
        className={`${
          sidemenuOpen
            ? "sidemenu-wrapper d-none d-lg-block show"
            : "sidemenu-wrapper d-none d-lg-block"
        }`}
      >
        <div className="sidemenu-content sidemenu-area">
          <button className="closeButton sideMenuCls" onClick={toggleSideMenu}>
            <i className="far fa-times" />
          </button>
          <div className="widget">
            <div className="th-widget-about">
              <div className="about-logo">
                <Link to="/">
                  <img src={logo} alt="logo" style={{ width: "300px" }} />
                </Link>
              </div>
              <p className="about-text">
                Quality-Driven Engineering and Project Management Solutions
              </p>
              <div className="th-social style2">
                <h6 className="title">FOLLOW US ON:</h6>
                <a href="#/">
                  <i className="fab fa-facebook-f" />
                </a>{" "}
                <a href="#/">
                  <i className="fab fa-instagram" />
                </a>
                <a href="#/">
                  <i className="fab fa-twitter" />
                </a>{" "}
                <a
                  href="https://www.linkedin.com/company/michaelsandassociates/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in" />
                </a>{" "}
              </div>
            </div>
          </div>
          <div className="widget widget_contact">
            <h3 className="widget_title">Get in touch!</h3>
            <div className="th-widget-contact">
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-location-dot" />
                </div>
                <p className="info-box_text">
                  12333 Sowden Rd Ste B, Houston, TX 77080-2059
                </p>
              </div>
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-envelope" />
                </div>
                <a
                  href="mailto:support@michaelsandassociate.com"
                  className="info-box_link"
                >
                  support@michaelsandassociate.com
                </a>
              </div>
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-phone" />
                </div>
                <a href="tel:8327865397" className="info-box_link">
                  (832) 786-5397
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          mobilemenuOpen ? "th-menu-wrapper th-body-visible" : "th-menu-wrapper"
        }`}
      >
        <div className="th-menu-area text-center">
          <button className="th-menu-toggle" onClick={toggleMobileMenu}>
            <i className="fal fa-times" />
          </button>
          <div className="mobile-logo">
            <Link to="/">
              <img src={logo} alt="logo" style={{ width: "180px" }} />
            </Link>
          </div>
          <div className="th-mobile-menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li
                className={`${
                  serviceDropdownOpen
                    ? "menu-item-has-children th-item-has-children th-active"
                    : "menu-item-has-children th-item-has-children"
                }`}
              >
                <a
                  href="#/"
                  onClick={toggleServiceDropdown}
                  className="activee"
                >
                  Services & Solutions <span className="th-mean-expand" />
                </a>
                <ul
                  className={`${
                    serviceDropdownOpen
                      ? "sub-menu th-submenu th-open"
                      : "sub-menu th-submenu"
                  }`}
                  style={!serviceDropdownOpen ? { display: "none" } : {}}
                >
                  <li>
                    <Link to="/project-management">Project Management</Link>
                  </li>
                  <li>
                    <Link to="/engineering">Engineering</Link>
                  </li>
                  <li
                    className={`${
                      fieldServicesDropdownOpen ? "th-active" : ""
                    }`}
                  >
                    <a href="#/" onClick={toggleFieldServicesDropdown}>
                      Field Services <span className="th-mean-expand" />
                    </a>
                    <ul
                      className="mb-4"
                      style={
                        !fieldServicesDropdownOpen ? { display: "none" } : {}
                      }
                    >
                      <li>
                        <Link to="/field-services#asset">
                          Asset Integrity & Corrosion Management
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#inspection">Inspection</Link>
                      </li>
                      <li>
                        <Link to="/field-services#material">
                          Material Verification & Records Management
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#oilfield">
                          Oilfield Supervision
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#construction">
                          Construction Management
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/staffing-support">Staffing Support</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header className="th-header header-layout-default">
        <div className="header-top">
          <div className="container th-container">
            <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
              <div className="col-auto d-none d-lg-block">
                <div className="header-links">
                  <ul>
                    <li>
                      <i className="far fa-phone" />
                      <a href="tel:8327865397">(832) 786-5397</a>
                    </li>
                    <li className="d-none d-xl-inline-block">
                      <i className="far fa-envelope" />
                      <a href="mailto:support@michaelsandassociate.com">
                        support@michaelsandassociate.com
                      </a>
                    </li>
                    <li>
                      <i className="far fa-location-dot" />
                      12333 Sowden Rd Ste B, Houston, TX 77080-2059
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-auto">
                <div className="header-links header-right">
                  <ul>
                    <li>
                      <div className="header-social">
                        <span className="social-title">Follow Us:</span>{" "}
                        <a href="#/">
                          <i className="fab fa-facebook-f" />
                        </a>{" "}
                        <a href="#/">
                          <i className="fab fa-instagram" />
                        </a>{" "}
                        <a href="#/">
                          <i className="fab fa-twitter" />
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/company/michaelsandassociates/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in" />
                        </a>{" "}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-wrapper">
          <div className="menu-area">
            <div className="container th-container">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <div className="header-logo">
                    <Link to="/">
                      <img
                        src={logoWhite}
                        alt="logo"
                        style={{ width: "180px" }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-auto">
                  <nav className="main-menu d-none d-lg-inline-block">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link className="activee" to="/services">
                          Services & Solutions
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/project-management">
                              Project Management
                            </Link>
                          </li>
                          <li>
                            <Link to="/engineering">Engineering</Link>
                          </li>
                          <li className="menu-item-has-children">
                            <Link to="/field-services">Field Services</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/field-services#asset">
                                  Asset Integrity & Corrosion Management
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#inspection">
                                  Inspection
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#material">
                                  Material Verification & Records Management
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#oilfield">
                                  Oilfield Supervision
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#construction">
                                  Construction Management
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/staffing-support">Staffing Support</Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </nav>
                  <button
                    type="button"
                    className="th-menu-toggle d-block d-lg-none"
                    onClick={toggleMobileMenu}
                  >
                    <i className="far fa-bars" />
                  </button>
                </div>
                <div className="col-auto d-none d-xl-block">
                  <div className="row">
                    <div className="col-auto">
                      <div className="header-button">
                        <button
                          type="button"
                          className="icon-btn sideMenuToggler"
                          onClick={toggleSideMenu}
                        >
                          <i className="fas fa-grid" />
                        </button>{" "}
                        <Link to="/consultation" className="th-btn ml-20">
                          GET A QUOTE <i className="fas fa-arrow-right ms-1" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="logo-bg lbg" />
          </div>
        </div>
      </header>
      <div className="breadcumb-wrapper wrap">
        <div className="container">
          <div className="breadcumb-content">
            <h1 className="breadcumb-title">Our Services</h1>
            <ul className="breadcumb-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Services</li>
            </ul>
          </div>
        </div>
      </div>
      <section className="overflow-hidden space" id="service-sec">
        <div className="container">
          <div className="title-area text-center">
            <span className="sub-title2">
              <img
                className="me-1"
                src="assets/img/icon/subtitle-img2-1.svg"
                alt="img"
              />
              Our Services
            </span>
            <h2 className="sec-title">The Best Services For You</h2>
          </div>
          <div className="row gy-4">
            <div className="col-md-6 col-lg-4">
              <div className="service-card style2 bg-shadow">
                <div className="service-card-bg-img ser" />
                <div className="service-content">
                  <div className="service-card-icon">
                    <img
                      src="assets/img/icon/service-icon-2-1.svg"
                      alt="icon"
                    />
                  </div>
                  <h3 className="box-title">
                    <Link to="/engineering">Engineering</Link>
                  </h3>
                  <div className="service-card-number">01</div>
                </div>
                <p className="service-card-text">
                  Offer technical design, development, and consulting for
                  projects in various fields such as mechanical, electrical, and
                  civil engineering. These services can help clients in design
                  optimization, prototyping, testing, and product launch.
                </p>
                <Link className="th-btn style10" to="/engineering">
                  Read More <i className="fas fa-arrow-right ms-1" />
                </Link>
                <div className="service-card-shape">
                  <img
                    src="assets/img/service/service-card-bg-2-1.png"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="service-card style2 bg-shadow">
                <div className="service-card-bg-img ser" />
                <div className="service-content">
                  <div className="service-card-icon">
                    <img
                      src="assets/img/icon/service-icon-2-4.svg"
                      alt="icon"
                    />
                  </div>
                  <h3 className="box-title">
                    <Link to="/project-management">Project Management</Link>
                  </h3>
                  <div className="service-card-number">02</div>
                </div>
                <p className="service-card-text">
                  Involves overseeing the planning, implementation, and tracking
                  of a specific short-term project. It involves coordinating the
                  efforts of team members to ensure that all aspects of the
                  project are completed on time and within budget.
                </p>
                <Link className="th-btn style10" to="/project-management">
                  Read More <i className="fas fa-arrow-right ms-1" />
                </Link>
                <div className="service-card-shape">
                  <img
                    src="assets/img/service/service-card-bg-2-1.png"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="service-card style2 bg-shadow">
                <div className="service-card-bg-img ser" />
                <div className="service-content">
                  <div className="service-card-icon">
                    <img
                      src="assets/img/icon/service-icon-1-3.svg"
                      alt="icon"
                    />
                  </div>
                  <h3 className="box-title">
                    <Link to="/field-services">Field Services</Link>
                  </h3>
                  <div className="service-card-number">03</div>
                </div>
                <p className="service-card-text">
                  This service involves on-site work and maintenance of
                  products, structures, and systems. This includes installation,
                  repair, and maintenance services, as well as testing and
                  inspection services.
                </p>
                <Link className="th-btn style10" to="/field-services">
                  Read More <i className="fas fa-arrow-right ms-1" />
                </Link>
                <div className="service-card-shape">
                  <img
                    src="assets/img/service/service-card-bg-2-1.png"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="service-card style2 bg-shadow">
                <div className="service-card-bg-img ser" />
                <div className="service-content">
                  <div className="service-card-icon">
                    <img
                      src="assets/img/icon/service-icon-1-1.svg"
                      alt="icon"
                    />
                  </div>
                  <h3 className="box-title">
                    <Link to="/staffing-support">Staffing Support</Link>
                  </h3>
                  <div className="service-card-number">04</div>
                </div>
                <p className="service-card-text">
                  This service provides staffing solutions for businesses and
                  organizations. This includes finding, recruiting, and hiring
                  employees, as well as providing support for human resource and
                  payroll management.
                </p>
                <Link className="th-btn style10" to="/staffing-support">
                  Read More <i className="fas fa-arrow-right ms-1" />
                </Link>
                <div className="service-card-shape">
                  <img
                    src="assets/img/service/service-card-bg-2-1.png"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="contact-area-1 space bg-smoke" id="contact-sec">
        <div className="shape-mockup contact-thumb1" style={{ zIndex: "1" }}>
          <img src="assets/img/normal/contact_thumb1.png" alt="img" />
        </div>
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-xl-6">
              <div className="title-area">
                <span className="sub-title2">
                  <img
                    className="me-1"
                    src="assets/img/icon/subtitle-img2-1.svg"
                    alt="img"
                  />
                  Contact Us
                </span>
                <h2 className="sec-title">25+ Years Of Experience</h2>
                <p>
                  Our quality-driven teams deliver sustainable solutions that
                  ensure complex project’s scope, schedule, and cost
                  requirements are met. As a value-driven organization, we are
                  dedicated to exceeding our clients expectations. People,
                  safety, and quality are core to both our successes and our
                  customer successes. Our focus is always on being your trusted
                  partner who delivers meaningful results.
                </p>
              </div>
              <div className="contact-wrap1">
                <div className="contact-form-wrap">
                  <form
                    className="contact-form ajax-contact"
                    onSubmit={sendMessage}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            id="name"
                            placeholder="Your Name*"
                            required
                            value={formData.name}
                            onChange={(e) => {
                              inputChange(e);
                            }}
                          />{" "}
                          <i className="fal fa-user" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            placeholder="Email Address*"
                            required
                            value={formData.email}
                            onChange={(e) => {
                              inputChange(e);
                            }}
                          />{" "}
                          <i className="fal fa-envelope" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="tel"
                            className="form-control"
                            name="phone"
                            id="number"
                            placeholder="Phone Number*"
                            required
                            value={formData.phone}
                            onChange={(e) => {
                              inputChange(e);
                            }}
                          />{" "}
                          <i className="fal fa-phone" />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            id="message"
                            cols={30}
                            rows={3}
                            className="form-control"
                            placeholder="Write Your Message*"
                            required
                            value={formData.message}
                            onChange={(e) => {
                              inputChange(e);
                            }}
                          />{" "}
                          <i className="fal fa-pen" />
                        </div>
                      </div>
                      <div className="form-btn col-12">
                        {loading ? (
                          <button className="th-btn w-100" type="button">
                            Sending Message...
                            <i className="fas fa-long-arrow-right ms-2" />
                          </button>
                        ) : (
                          <button className="th-btn w-100" type="submit">
                            Send Message
                            <i className="fas fa-long-arrow-right ms-2" />
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                {/* <div className="newsletter-card bg-title">
                  <div className="title-wrap">
                    <div className="icon">
                      <img src="assets/img/icon/envelope-icon.svg" alt="img" />
                    </div>
                    <div className="details">
                      <span className="sub-title">Newsletter</span>
                      <h3 className="title text-white">SUBSCRIBE NOW !</h3>
                    </div>
                  </div>
                  <p className="content">
                    Please Crate Your E-mail And Subscribe Now*
                  </p>
                  <form className="newsletter-form form-group">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email Email"
                      required
                    />{" "}
                    <button type="submit" className="th-btn style3">
                      <i className="fas fa-paper-plane ms-1" />
                    </button>
                  </form>
                  <div className="contact-feature">
                    <div className="contact-feature-icon">
                      <i className="fas fa-phone" />
                    </div>
                    <div className="media-body">
                      <p className="contact-feature_label">Call Us Anytime:</p>
                      <a
                        href="tel:+011456586986"
                        className="contact-feature_link"
                      >
                        +19524357106
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </>
  );
};

export default Services;
