import { useState } from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const subscribe = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post(
        "https://michaelsandassociates.onrender.com/api/mailing-list",
        {
          email,
        }
      );

      toast.success("Email address added to mailing list!");
      setLoading(false);
      setEmail("");
    } catch (error) {
      if (error.response.data.error === "This user already exists") {
        toast.error("Email address already in mailing list");
        setLoading(false);
      } else {
        toast.error("Something went wrong");
        setLoading(false);
      }
    }
  };

  return (
    <footer
      className="footer-wrapper footer-layout1 foot"
      data-bg-src="assets/img/bg/footer1-bg.png"
      data-overlay="title"
      data-opacity={9}
    >
      <div className="widget-area space-top z-index-common">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-md-6 col-xxl-3 col-xl-3">
              <div className="widget footer-widget">
                <div className="th-widget-about">
                  <div className="about-logo">
                    <Link to="/">
                      <img src={logo} alt="logo" style={{ width: "200px" }} />
                    </Link>
                  </div>
                  <p className="about-text mb-4">
                    At Michael & Associates, we are passionate about helping our
                    clients achieve their goals, and we are committed to
                    delivering the highest level of service and support to
                    achieve their success.
                  </p>
                  <div className="th-widget-contact">
                    <h6 className="info-box-title text-white mb-1">
                      Phone Number
                    </h6>
                    <div className="info-box-wrap">
                      <div className="info-box_icon">
                        <i className="fas fa-phone" />
                      </div>

                      <a className="info-box_link" href="tel:8327865397">
                        (832) 786-5397
                      </a>
                    </div>
                    <h6 className="info-box-title text-white mb-1">
                      Email address
                    </h6>
                    <div className="info-box-wrap">
                      <div className="info-box_icon">
                        <i className="fas fa-envelope" />
                      </div>

                      <a
                        className="info-box_link"
                        href="mailto:support@michaelsandassociate.com"
                      >
                        support@michaelsandassociate.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-auto">
              <div className="widget widget_nav_menu footer-widget">
                <h3 className="widget_title">Quick Links</h3>
                <div className="menu-all-pages-container">
                  <ul className="menu">
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>

                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/services">Our Services</Link>
                    </li>
                    <li>
                      <Link to="/clients">Our Clients</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ's</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-xxl-3 col-xl-3">
              <div className="widget newsletter-widget footer-widget">
                <h3 className="widget_title">Newsletter</h3>
                <p className="footer-text">
                  Sign Up to get updates &amp; news about us . Get Latest Deals
                  straight to your email address.
                </p>
                <form
                  className="newsletter-form form-group"
                  onSubmit={subscribe}
                >
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Email address"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  />{" "}
                  {loading ? (
                    <button type="button" className="th-btn style3" disabled>
                      <i className="fas fa-paper-plane ms-1" />
                    </button>
                  ) : (
                    <button type="submit" className="th-btn style3">
                      <i className="fas fa-paper-plane ms-1" />
                    </button>
                  )}
                </form>
                <div className="th-social">
                  <a href="#/">
                    <i className="fab fa-facebook-f" />
                  </a>{" "}
                  <a href="#/">
                    <i className="fab fa-twitter" />
                  </a>{" "}
                  <a href="#/">
                    <i className="fab fa-instagram" />
                  </a>{" "}
                  <a
                    href="https://www.linkedin.com/company/michaelsandassociates/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin-in" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-wrap">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              <p className="copyright-text">
                Copyright © {new Date().getFullYear()} Michaels & Associates.
                Powered by{" "}
                <a
                  href="https://www.arvotek.org"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "#71cee8" }}
                >
                  Arvotek
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </footer>
  );
};

export default Footer;
