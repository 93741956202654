import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo-dark.png";
import logoWhite from "../assets/logo.png";
import Footer from "../components/Footer";
import ClientSlider from "../components/ClientSlider";
// import Testimonials from "../components/Testimonials";
import workerss from "../assets/workers.jpg";
import workersTwo from "../assets/workers-2.jpg";

const About = () => {
  const [sidemenuOpen, setSideMenuOpen] = useState(false);
  const [mobilemenuOpen, setMobileMenuOpen] = useState(false);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [fieldServicesDropdownOpen, setFieldServicesDropdownOpen] =
    useState(false);
  const [tabActive, setTabActive] = useState("mission");

  const toggleSideMenu = () => {
    setSideMenuOpen(!sidemenuOpen);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobilemenuOpen);
  };

  const toggleServiceDropdown = () => {
    setServiceDropdownOpen(!serviceDropdownOpen);
  };

  const toggleFieldServicesDropdown = () => {
    setFieldServicesDropdownOpen(!fieldServicesDropdownOpen);
  };

  return (
    <>
      <div
        className={`${
          sidemenuOpen
            ? "sidemenu-wrapper d-none d-lg-block show"
            : "sidemenu-wrapper d-none d-lg-block"
        }`}
      >
        <div className="sidemenu-content sidemenu-area">
          <button className="closeButton sideMenuCls" onClick={toggleSideMenu}>
            <i className="far fa-times" />
          </button>
          <div className="widget">
            <div className="th-widget-about">
              <div className="about-logo">
                <Link to="/">
                  <img src={logo} alt="logo" style={{ width: "300px" }} />
                </Link>
              </div>
              <p className="about-text">
                Quality-Driven Engineering and Project Management Solutions
              </p>
              <div className="th-social style2">
                <h6 className="title">FOLLOW US ON:</h6>
                <a href="#/">
                  <i className="fab fa-facebook-f" />
                </a>{" "}
                <a href="#/">
                  <i className="fab fa-instagram" />
                </a>
                <a href="#/">
                  <i className="fab fa-twitter" />
                </a>{" "}
                <a
                  href="https://www.linkedin.com/company/michaelsandassociates/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in" />
                </a>{" "}
              </div>
            </div>
          </div>
          <div className="widget widget_contact">
            <h3 className="widget_title">Get in touch!</h3>
            <div className="th-widget-contact">
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-location-dot" />
                </div>
                <p className="info-box_text">
                  12333 Sowden Rd Ste B, Houston, TX 77080-2059
                </p>
              </div>
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-envelope" />
                </div>
                <a
                  href="mailto:support@michaelsandassociate.com"
                  className="info-box_link"
                >
                  support@michaelsandassociate.com
                </a>
              </div>
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-phone" />
                </div>
                <a href="tel:8327865397" className="info-box_link">
                  (832) 786-5397
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          mobilemenuOpen ? "th-menu-wrapper th-body-visible" : "th-menu-wrapper"
        }`}
      >
        <div className="th-menu-area text-center">
          <button className="th-menu-toggle" onClick={toggleMobileMenu}>
            <i className="fal fa-times" />
          </button>
          <div className="mobile-logo">
            <Link to="/">
              <img src={logo} alt="logo" style={{ width: "180px" }} />
            </Link>
          </div>
          <div className="th-mobile-menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link className="activee" to="/about">
                  About Us
                </Link>
              </li>
              <li
                className={`${
                  serviceDropdownOpen
                    ? "menu-item-has-children th-item-has-children th-active"
                    : "menu-item-has-children th-item-has-children"
                }`}
              >
                <a href="#/" onClick={toggleServiceDropdown}>
                  Services & Solutions <span className="th-mean-expand" />
                </a>
                <ul
                  className={`${
                    serviceDropdownOpen
                      ? "sub-menu th-submenu th-open"
                      : "sub-menu th-submenu"
                  }`}
                  style={!serviceDropdownOpen ? { display: "none" } : {}}
                >
                  <li>
                    <Link to="/project-management">Project Management</Link>
                  </li>
                  <li>
                    <Link to="/engineering">Engineering</Link>
                  </li>
                  <li
                    className={`${
                      fieldServicesDropdownOpen ? "th-active" : ""
                    }`}
                  >
                    <a href="#/" onClick={toggleFieldServicesDropdown}>
                      Field Services <span className="th-mean-expand" />
                    </a>
                    <ul
                      className="mb-4"
                      style={
                        !fieldServicesDropdownOpen ? { display: "none" } : {}
                      }
                    >
                      <li>
                        <Link to="/field-services#asset">
                          Asset Integrity & Corrosion Management
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#inspection">Inspection</Link>
                      </li>
                      <li>
                        <Link to="/field-services#material">
                          Material Verification & Records Management
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#oilfield">
                          Oilfield Supervision
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#construction">
                          Construction Management
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/staffing-support">Staffing Support</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header className="th-header header-layout-default">
        <div className="header-top">
          <div className="container th-container">
            <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
              <div className="col-auto d-none d-lg-block">
                <div className="header-links">
                  <ul>
                    <li>
                      <i className="far fa-phone" />
                      <a href="tel:8327865397">(832) 786-5397</a>
                    </li>
                    <li className="d-none d-xl-inline-block">
                      <i className="far fa-envelope" />
                      <a href="mailto:support@michaelsandassociate.com">
                        support@michaelsandassociate.com
                      </a>
                    </li>
                    <li>
                      <i className="far fa-location-dot" />
                      12333 Sowden Rd Ste B, Houston, TX 77080-2059
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-auto">
                <div className="header-links header-right">
                  <ul>
                    <li>
                      <div className="header-social">
                        <span className="social-title">Follow Us:</span>{" "}
                        <a href="#/">
                          <i className="fab fa-facebook-f" />
                        </a>{" "}
                        <a href="#/">
                          <i className="fab fa-instagram" />
                        </a>{" "}
                        <a href="#/">
                          <i className="fab fa-twitter" />
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/company/michaelsandassociates/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in" />
                        </a>{" "}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-wrapper">
          <div className="menu-area">
            <div className="container th-container">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <div className="header-logo">
                    <Link to="/">
                      <img
                        src={logoWhite}
                        alt="logo"
                        style={{ width: "180px" }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-auto">
                  <nav className="main-menu d-none d-lg-inline-block">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link className="activee" to="/about">
                          About Us
                        </Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="/services">Services & Solutions</Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/project-management">
                              Project Management
                            </Link>
                          </li>
                          <li>
                            <Link to="/engineering">Engineering</Link>
                          </li>
                          <li className="menu-item-has-children">
                            <Link to="/field-services">Field Services</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/field-services#asset">
                                  Asset Integrity & Corrosion Management
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#inspection">
                                  Inspection
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#material">
                                  Material Verification & Records Management
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#oilfield">
                                  Oilfield Supervision
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#construction">
                                  Construction Management
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/staffing-support">Staffing Support</Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </nav>
                  <button
                    type="button"
                    className="th-menu-toggle d-block d-lg-none"
                    onClick={toggleMobileMenu}
                  >
                    <i className="far fa-bars" />
                  </button>
                </div>
                <div className="col-auto d-none d-xl-block">
                  <div className="row">
                    <div className="col-auto">
                      <div className="header-button">
                        <button
                          type="button"
                          className="icon-btn sideMenuToggler"
                          onClick={toggleSideMenu}
                        >
                          <i className="fas fa-grid" />
                        </button>{" "}
                        <Link to="/consultation" className="th-btn ml-20">
                          GET A QUOTE <i className="fas fa-arrow-right ms-1" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="logo-bg lbg" />
          </div>
        </div>
      </header>
      <div className="breadcumb-wrapper wrap">
        <div className="container">
          <div className="breadcumb-content">
            <h1 className="breadcumb-title">About Us</h1>
            <ul className="breadcumb-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>About Us</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="overflow-hidden space overflow-hidden" id="about-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6">
              <img
                className="tilt-active"
                src={workersTwo}
                alt="About"
                style={{ width: "100%" }}
              />
            </div>
            <div className="col-xl-6">
              <div className="title-area mb-30">
                <span className="sub-title4">About Us</span>
                <h2 className="sec-title fw-semibold">
                  We Always Put You First
                </h2>
              </div>
              <p className="mt-n2">
                Michaels & Associates is an Engineering and Project Management
                consulting firm headquartered in Houston, Texas. We are known
                for innovation, agility, and remarkable problem solving
                capabilities; leveraging on our technical experience and deep
                industry expertise to provide customized solutions for our
                clients globally.
              </p>
              <p className="mt-n2 mb-35">
                Our quality-driven teams deliver sustainable solutions that
                ensure complex project’s scope, schedule, and cost requirements
                are met. As a value-driven organization, we are dedicated to
                exceeding our clients expectations. People, safety, and quality
                are core to both our successes and our customer successes. Our
                focus is always on being your trusted partner who delivers
                meaningful results.
              </p>
              {/* <div className="about-grid2">
                <div className="icon">
                  <img src="assets/img/icon/about-grid-icon1.svg" alt="img" />
                </div>
                <div className="about-grid-details">
                  <h3 className="about-grid_title h6">Worldwide Services</h3>
                  <p>
                    With our headquarters in Houston, Texas and a global
                    presence, we have the resources and expertise to provide
                    customized solutions for clients globally.
                  </p>
                </div>
              </div>
              <div className="about-grid2">
                <div className="icon">
                  <img src="assets/img/icon/about-grid-icon2.svg" alt="img" />
                </div>
                <div className="about-grid-details">
                  <h3 className="about-grid_title h6">
                    Best Customer Support Award Winner
                  </h3>
                  <p>
                    Our ultimate goal is to ensure customer satisfaction and we
                    go above and beyond to make sure our clients are happy with
                    the results.
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <section
        className="z-index-common"
        data-pos-for=".why-sec-v5"
        data-sec-pos="bottom-half"
      >
        <div className="container th-container2">
          <div className="counter-area-4 bg-white bg-shadow">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-5">
                <div className="title-area mb-xl-0 text-center text-xl-start mb-50">
                  <span className="sub-title4">Our Company Achievements</span>
                  <h2 className="sec-title fw-semibold">
                    Industrial Strength, Global Impact
                  </h2>
                  <Link to="/consultation" className="th-btn mt-10">
                    Make An Appointment{" "}
                    <i className="fa-regular fa-arrow-right ms-2" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-7">
                <div className="counter-wrap4">
                  <div className="row">
                    <div className="col-sm-6 counter-grid-wrap">
                      <div className="counter-grid style4">
                        <div className="counter-grid_icon">
                          <img
                            src="assets/img/icon/counter-icon4-1.svg"
                            alt="img"
                          />
                        </div>
                        <div className="details">
                          <h2 className="counter-grid_number">
                            <span className="counter-number">4</span>k
                            <span className="text-theme fw-normal">+</span>
                          </h2>
                          <p className="counter-grid_text">
                            Projects Completed
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 counter-grid-wrap">
                      <div className="counter-grid style4">
                        <div className="counter-grid_icon">
                          <img
                            src="assets/img/icon/counter-icon4-2.svg"
                            alt="img"
                          />
                        </div>
                        <div className="details">
                          <h2 className="counter-grid_number">
                            <span className="counter-number">30</span>
                            <span className="text-theme fw-normal">+</span>
                          </h2>
                          <p className="counter-grid_text">Team Members</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="why-sec-v5 overflow-hidden bg-smoke space">
        <div
          className="shape-mockup service2-bg-shape1 jump"
          data-top={0}
          data-left={0}
        >
          <img src="assets/img/service/service-bg-shape2-1.png" alt="img" />
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9 col-lg-10">
              <div className="title-area text-center">
                <span className="sub-title4">Why Choose Us</span>
                <h2 className="sec-title fw-semibold">
                  Real People. Real Work. Real Rewards
                </h2>
              </div>
            </div>
          </div>
          <div className="tab-menu1 filter-menu-active">
            <button
              data-filter=".cat2"
              type="button"
              className={`${tabActive === "mission" ? "active" : ""}`}
              onClick={() => setTabActive("mission")}
            >
              Our Mission
            </button>{" "}
            <button
              data-filter=".cat3"
              type="button"
              className={`${tabActive === "services" ? "active" : ""}`}
              onClick={() => setTabActive("services")}
            >
              Our Services
            </button>{" "}
          </div>
          <div className="mission-box-wrap mt-50 filter-active-cat1">
            {tabActive === "mission" && (
              <div className="filter-item cat2">
                <div className="mission-grid">
                  <div
                    className="mission-img"
                    data-overlay="title"
                    data-opacity={2}
                  >
                    <img src={workerss} alt="mission img" />{" "}
                  </div>
                  <div className="mission-content">
                    <h3 className="mission-title">
                      Leading The Way In Engineering & Project Management
                    </h3>
                    <div className="checklist">
                      <ul>
                        <li>Innovative Solutions</li>
                        <li>Agile Approach</li>
                        <li>Trusted Partner</li>
                        <li>Quality-Driven Teams</li>
                        <li>Global Presence</li>
                        <li>Expertise and experience</li>
                        <li>Flexibility</li>
                        <li>Customer Satisfaction</li>
                      </ul>
                    </div>
                    <div className="mission-feature-wrap">
                      <div className="mission-feature">
                        <div className="mission-feature_icon">
                          <img
                            src="assets/img/icon/mission_1_1.svg"
                            alt="icon"
                          />
                        </div>
                        <div className="media-body">
                          <h4 className="mission-feature_title">Engineering</h4>
                        </div>
                      </div>
                      <div className="mission-feature">
                        <div className="mission-feature_icon">
                          <img
                            src="assets/img/icon/mission_1_2.svg"
                            alt="icon"
                          />
                        </div>
                        <div className="media-body">
                          <h4 className="mission-feature_title">
                            Project Management
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {tabActive === "services" && (
              <div className="filter-item cat3">
                <div className="mission-grid">
                  <div
                    className="mission-img"
                    data-overlay="title"
                    data-opacity={2}
                  >
                    <img
                      src="assets/img/normal/mission_1_3.jpg"
                      alt="mission img"
                    />{" "}
                  </div>
                  <div className="mission-content">
                    <h3 className="mission-title">
                      We Always Provide Best Quality Services
                    </h3>

                    <div className="checklist">
                      <ul>
                        <li>Project Management</li>
                        <li>Engineering</li>
                        <li>Field Services</li>
                        <li>Staffing Support</li>
                      </ul>
                    </div>
                    <div className="mission-feature-wrap">
                      <div className="mission-feature">
                        <div className="mission-feature_icon">
                          <img
                            src="assets/img/icon/mission_1_1.svg"
                            alt="icon"
                          />
                        </div>
                        <div className="media-body">
                          <h4 className="mission-feature_title">Engineering</h4>
                        </div>
                      </div>
                      <div className="mission-feature">
                        <div className="mission-feature_icon">
                          <img
                            src="assets/img/icon/mission_1_2.svg"
                            alt="icon"
                          />
                        </div>
                        <div className="media-body">
                          <h4 className="mission-feature_title">
                            Project Management
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ClientSlider />
      {/* <Testimonials /> */}
      <Footer />
    </>
  );
};

export default About;
