import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo-dark.png";
import Footer from "../components/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClientSlider from "../components/ClientSlider";

const Home = () => {
  const [sidemenuOpen, setSideMenuOpen] = useState(false);
  const [mobilemenuOpen, setMobileMenuOpen] = useState(false);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [fieldServicesDropdownOpen, setFieldServicesDropdownOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
    message: "",
  });

  const inputChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const toggleSideMenu = () => {
    setSideMenuOpen(!sidemenuOpen);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobilemenuOpen);
  };

  const toggleServiceDropdown = () => {
    setServiceDropdownOpen(!serviceDropdownOpen);
  };

  const toggleFieldServicesDropdown = () => {
    setFieldServicesDropdownOpen(!fieldServicesDropdownOpen);
  };

  const sendMessage = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      await axios.post(
        "https://michaelsandassociates.onrender.com/api/consultation",
        {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          service: formData.service,
          message: formData.message,
        }
      );

      toast.success("Message sent successfully!");
      setLoading(false);
      setFormData((prevState) => ({
        ...prevState,
        name: "",
        email: "",
        phone: "",
        service: "",
        message: "",
      }));
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <div
        className={`${
          sidemenuOpen
            ? "sidemenu-wrapper d-none d-lg-block show"
            : "sidemenu-wrapper d-none d-lg-block"
        }`}
      >
        <div className="sidemenu-content sidemenu-area">
          <button className="closeButton sideMenuCls" onClick={toggleSideMenu}>
            <i className="far fa-times" />
          </button>
          <div className="widget">
            <div className="th-widget-about">
              <div className="about-logo">
                <Link to="/">
                  <img src={logo} alt="logo" style={{ width: "300px" }} />
                </Link>
              </div>
              <p className="about-text">
                Quality-Driven Engineering and Project Management Solutions
              </p>
              <div className="th-social style2">
                <h6 className="title">FOLLOW US ON:</h6>
                <a href="#/">
                  <i className="fab fa-facebook-f" />
                </a>{" "}
                <a href="#/">
                  <i className="fab fa-instagram" />
                </a>
                <a href="#/">
                  <i className="fab fa-twitter" />
                </a>{" "}
                <a
                  href="https://www.linkedin.com/company/michaelsandassociates/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in" />
                </a>{" "}
              </div>
            </div>
          </div>
          <div className="widget widget_contact">
            <h3 className="widget_title">Get in touch!</h3>
            <div className="th-widget-contact">
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-location-dot" />
                </div>
                <p className="info-box_text">
                  12333 Sowden Rd Ste B, Houston, TX 77080-2059
                </p>
              </div>
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-envelope" />
                </div>
                <a
                  href="mailto:support@michaelsandassociate.com"
                  className="info-box_link"
                >
                  support@michaelsandassociate.com
                </a>
              </div>
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-phone" />
                </div>
                <a href="tel:8327865397" className="info-box_link">
                  (832) 786-5397
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          mobilemenuOpen ? "th-menu-wrapper th-body-visible" : "th-menu-wrapper"
        }`}
      >
        <div className="th-menu-area text-center">
          <button className="th-menu-toggle" onClick={toggleMobileMenu}>
            <i className="fal fa-times" />
          </button>
          <div className="mobile-logo">
            <Link to="/">
              <img src={logo} alt="logo" style={{ width: "180px" }} />
            </Link>
          </div>
          <div className="th-mobile-menu">
            <ul>
              <li>
                <Link className="activee" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li
                className={`${
                  serviceDropdownOpen
                    ? "menu-item-has-children th-item-has-children th-active"
                    : "menu-item-has-children th-item-has-children"
                }`}
              >
                <a href="#/" onClick={toggleServiceDropdown}>
                  Services & Solutions <span className="th-mean-expand" />
                </a>
                <ul
                  className={`${
                    serviceDropdownOpen
                      ? "sub-menu th-submenu th-open"
                      : "sub-menu th-submenu"
                  }`}
                  style={!serviceDropdownOpen ? { display: "none" } : {}}
                >
                  <li>
                    <Link to="/project-management">Project Management</Link>
                  </li>
                  <li>
                    <Link to="/engineering">Engineering</Link>
                  </li>
                  <li
                    className={`${
                      fieldServicesDropdownOpen ? "th-active" : ""
                    }`}
                  >
                    <a href="#/" onClick={toggleFieldServicesDropdown}>
                      Field Services <span className="th-mean-expand" />
                    </a>
                    <ul
                      className="mb-4"
                      style={
                        !fieldServicesDropdownOpen ? { display: "none" } : {}
                      }
                    >
                      <li>
                        <Link to="/field-services#asset">
                          Asset Integrity & Corrosion Management
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#inspection">Inspection</Link>
                      </li>
                      <li>
                        <Link to="/field-services#material">
                          Material Verification & Records Management
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#oilfield">
                          Oilfield Supervision
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#construction">
                          Construction Management
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/staffing-support">Staffing Support</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header className="th-header header-layout1">
        <div className="header-top ht">
          <div className="container th-container2">
            <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
              <div className="col-auto d-none d-lg-block">
                <div className="header-links">
                  <ul>
                    <li>Welcome to Michaels & Associates</li>
                  </ul>
                </div>
              </div>
              <div className="col-auto">
                <div className="header-links header-right">
                  <ul>
                    <li>
                      <div className="header-social">
                        <a href="#/">
                          <i className="fab fa-facebook-f" />
                        </a>{" "}
                        <a href="#/">
                          <i className="fab fa-instagram" />
                        </a>{" "}
                        <a href="#/">
                          <i className="fab fa-twitter" />
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/company/michaelsandassociates/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in" />
                        </a>{" "}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-wrapper">
          <div className="menu-area">
            <div className="container th-container2">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <div className="header-logo">
                    <Link to="/">
                      <img src={logo} alt="logo" className="lg-h" />
                    </Link>
                  </div>
                </div>
                <div className="col-auto d-none d-lg-block sticy-d-none">
                  <div className="header-middle">
                    <div className="header-link">
                      <i className="fas fa-phone" />
                      <div>
                        <p>Call Us Any Time:</p>
                        <a href="tel:8327865397" className="header-single-link">
                          (832) 786-5397
                        </a>
                      </div>
                    </div>
                    <div className="header-link">
                      <i className="fas fa-envelope" />
                      <div>
                        <p>Email Us:</p>
                        <a
                          href="mailto:support@michaelsandassociate.com"
                          className="header-single-link"
                        >
                          support@michaelsandassociate.com
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto sticy-d-block">
                  <nav className="main-menu d-none d-lg-inline-block">
                    <ul>
                      <li>
                        <Link className="activee" to="/">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="/services">Services & Solutions</Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/project-management">
                              Project Management
                            </Link>
                          </li>
                          <li>
                            <Link to="/engineering">Engineering</Link>
                          </li>
                          <li className="menu-item-has-children">
                            <Link to="/field-services">Field Services</Link>
                            <ul
                              className="sub-menu"
                              style={{
                                left: "auto",
                                right: "100%",
                                marginRight: "20px",
                              }}
                            >
                              <li>
                                <Link to="/field-services#asset">
                                  Asset Integrity & Corrosion Management
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#inspection">
                                  Inspection
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#material">
                                  Material Verification & Records Management
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#oilfield">
                                  Oilfield Supervision
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#construction">
                                  Construction Management
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/staffing-support">Staffing Support</Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="col-auto d-lg-none">
                  <button
                    type="button"
                    className="th-menu-toggle d-block d-lg-none"
                    onClick={toggleMobileMenu}
                  >
                    <i className="far fa-bars" />
                  </button>
                </div>
              </div>
              <div className="main-menu-area">
                <div className="row justify-content-between align-items-center">
                  <div className="col-auto">
                    <nav className="main-menu d-none d-lg-inline-block">
                      <ul>
                        <li>
                          <Link className="activee" to="/">
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link to="/about">About Us</Link>
                        </li>
                        <li className="menu-item-has-children">
                          <Link to="/services">Services & Solutions</Link>
                          <ul className="sub-menu">
                            <li>
                              <Link to="/project-management">
                                Project Management
                              </Link>
                            </li>
                            <li>
                              <Link to="/engineering">Engineering</Link>
                            </li>
                            <li className="menu-item-has-children">
                              <Link to="/field-services">Field Services</Link>
                              <ul className="sub-menu">
                                <li>
                                  <Link to="/field-services#asset">
                                    Asset Integrity & Corrosion Management
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/field-services#inspection">
                                    Inspection
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/field-services#material">
                                    Material Verification & Records Management
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/field-services#oilfield">
                                    Oilfield Supervision
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/field-services#construction">
                                    Construction Management
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <Link to="/staffing-support">
                                Staffing Support
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <Link to="/contact">Contact Us</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="col-auto d-none d-lg-block">
                    <div className="header-button">
                      <button
                        type="button"
                        className="icon-btn style2 sideMenuToggler"
                        onClick={toggleSideMenu}
                      >
                        <i className="fas fa-grid" />
                      </button>{" "}
                      <Link to="/consultation" className="th-btn style8 ml-25">
                        GET A QUOTE <i className="fas fa-arrow-right ms-1" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="logo-bg" />
          </div>
        </div>
      </header>

      <div className="th-hero-wrapper hero-1" id="hero">
        <div
          className="hero-slider-1 th-carousel"
          data-fade="true"
          data-slide-show={1}
          data-md-slide-show={1}
          data-dots="true"
          data-adaptive-height="true"
        >
          <Slider
            fade
            adaptiveHeight
            slidesToShow={1}
            autoplay
            autoplaySpeed={4000}
          >
            <div className="th-hero-slide">
              <div className="th-hero-bg her">
                <img src="assets/img/hero/hero_overlay_1_1.jpg" alt="" />
              </div>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-xxl-8 col-lg-7 col-md-8">
                    <div className="hero-style1">
                      <h1
                        className="hero-title text-white"
                        data-ani="slideinleft"
                        data-ani-delay="0.4s"
                      >
                        Quality-Driven Engineering{" "}
                        <span className="text-theme fw-normal">Solutions</span>
                      </h1>
                      <p
                        className="hero-text"
                        data-ani="slideinleft"
                        data-ani-delay="0.6s"
                      >
                        Innovation, Agility, Remarkable Problem Solving
                        capabilities that make the world a better place.
                      </p>
                      <div
                        className="btn-group"
                        data-ani="slideinleft"
                        data-ani-delay="0.8s"
                      >
                        <Link to="/about" className="th-btn style3">
                          Discover More{" "}
                          <i className="fas fa-arrow-right ms-2" />
                        </Link>{" "}
                        <Link to="/services" className="th-btn style2">
                          Our Services <i className="fas fa-arrow-right ms-2" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="th-hero-slide">
              <div className="th-hero-bg her2">
                <img src="assets/img/hero/hero_overlay_1_1.jpg" alt="" />
              </div>
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-xxl-8 col-lg-7 col-md-8">
                    <div className="hero-style1">
                      <h1
                        className="hero-title text-white"
                        data-ani="slideinleft"
                        data-ani-delay="0.4s"
                      >
                        Effective Project Management{" "}
                        <span className="text-theme fw-normal">Solutions</span>
                      </h1>
                      <p
                        className="hero-text"
                        data-ani="slideinleft"
                        data-ani-delay="0.6s"
                      >
                        Innovation, Agility and Remarkable problem solving
                        capabilities that makes the world a better place
                      </p>
                      <div
                        className="btn-group"
                        data-ani="slideinleft"
                        data-ani-delay="0.8s"
                      >
                        <Link to="/about" className="th-btn style3">
                          Discover More{" "}
                          <i className="fas fa-arrow-right ms-2" />
                        </Link>{" "}
                        <Link to="/services" className="th-btn style2">
                          Our Services <i className="fas fa-arrow-right ms-2" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
        <div className="hero-shape" />
      </div>
      <div className="overflow-hidden space overflow-hidden" id="about-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <p className="mt-n2">
                Michaels & Associates is an Engineering and Project Management
                consulting firm headquartered in Houston, Texas. We are known
                for innovation, agility, and remarkable problem solving
                capabilities; leveraging on our technical experience and deep
                industry expertise to provide customized solutions for our
                clients globally.
              </p>
              <p className="mt-n2 mb-35">
                Our quality-driven teams deliver sustainable solutions to ensure
                that complex project analysis, scheduling and cost-assessment
                requirements are met. As a value-driven organization, we are
                dedicated to exceeding the expectations of our clients. People,
                safety, and quality are key components to success, not only for
                us but also for our customers.. Our focus is always on being
                your trusted partner who delivers meaningful results.
              </p>
              {/* <div className="about-grid2">
                <div className="icon">
                  <img src="assets/img/icon/about-grid-icon1.svg" alt="img" />
                </div>
                <div className="about-grid-details">
                  <h3 className="about-grid_title h6">Worldwide Services</h3>
                  <p>
                    With our headquarters in Houston, Texas and a global
                    presence, we have the resources and expertise to provide
                    customized solutions for clients globally.
                  </p>
                </div>
              </div> */}
              {/* <div className="about-grid2">
                <div className="icon">
                  <img src="assets/img/icon/about-grid-icon2.svg" alt="img" />
                </div>
                <div className="about-grid-details">
                  <h3 className="about-grid_title h6">
                    Best Customer Support Award Winner
                  </h3>
                  <p>
                    Our ultimate goal is to ensure customer satisfaction and we
                    go above and beyond to make sure our clients are happy with
                    the results.
                  </p>
                </div>
              </div> */}
              <div className="btn-group mt-40">
                <Link to="/about" className="th-btn">
                  More About Us
                  <i className="fa-regular fa-arrow-right ms-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="counter-area-1 bg-theme ct">
        <div className="container">
          <div className="row justify-content-between gy-5">
            <div className="col-sm-6 col-xl-3">
              <div className="counter-grid">
                <div className="counter-grid_icon">
                  <img src="assets/img/icon/counter-icon1-1.svg" alt="img" />
                </div>
                <div className="details">
                  <h2 className="counter-grid_number">
                    <span className="counter-number">4</span>k+
                  </h2>
                  <p className="counter-grid_text">Projects Completed</p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-3">
              <div className="counter-grid">
                <div className="counter-grid_icon">
                  <img src="assets/img/icon/counter-icon1-2.svg" alt="img" />
                </div>
                <div className="details">
                  <h2 className="counter-grid_number">
                    <span className="counter-number">30</span>+
                  </h2>
                  <p className="counter-grid_text">Team Members</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="why-area-1 space overflow-hidden">
        <div className="container">
          <div className="row flex-xl-row flex-column-reverse">
            <div className="col-xl-10">
              <div className="wcu-wrap1 bg-theme yy">
                <div className="title-area mb-25">
                  <span className="sub-title text-white">WHY CHOOSE US</span>
                  <h2 className="sec-title text-white">
                    Real People. Real Work. Real Rewards.
                  </h2>
                </div>
                <div className="row gy-4">
                  <div className="col-lg-6">
                    <div className="wcu-box">
                      <div className="wcu-box_icon">
                        <img src="assets/img/icon/wcu-icon-1-1.svg" alt="img" />
                      </div>
                      <div className="wcu-box_details">
                        <h3 className="box-title">Innovative Solutions</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="wcu-box">
                      <div className="wcu-box_icon">
                        <img src="assets/img/icon/wcu-icon-1-1.svg" alt="img" />
                      </div>
                      <div className="wcu-box_details">
                        <h3 className="box-title">Agile Approach</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="wcu-box">
                      <div className="wcu-box_icon">
                        <img src="assets/img/icon/wcu-icon-1-1.svg" alt="img" />
                      </div>
                      <div className="wcu-box_details">
                        <h3 className="box-title">Trusted Partner</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="wcu-box">
                      <div className="wcu-box_icon">
                        <img src="assets/img/icon/wcu-icon-1-1.svg" alt="img" />
                      </div>
                      <div className="wcu-box_details">
                        <h3 className="box-title">Quality-Driven Teams</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="wcu-box">
                      <div className="wcu-box_icon">
                        <img src="assets/img/icon/wcu-icon-1-1.svg" alt="img" />
                      </div>
                      <div className="wcu-box_details">
                        <h3 className="box-title">Global Presence</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="wcu-box">
                      <div className="wcu-box_icon">
                        <img src="assets/img/icon/wcu-icon-1-1.svg" alt="img" />
                      </div>
                      <div className="wcu-box_details">
                        <h3 className="box-title">Expertise and experience</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="wcu-box">
                      <div className="wcu-box_icon">
                        <img src="assets/img/icon/wcu-icon-1-1.svg" alt="img" />
                      </div>
                      <div className="wcu-box_details">
                        <h3 className="box-title">Flexibility</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="wcu-box">
                      <div className="wcu-box_icon">
                        <img src="assets/img/icon/wcu-icon-1-1.svg" alt="img" />
                      </div>
                      <div className="wcu-box_details">
                        <h3 className="box-title">Customer Satisfaction</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn-group mt-40">
                  <Link className="th-btn style6" to="/about">
                    Learn More <i className="fas fa-arrow-right ms-1" />
                  </Link>{" "}
                  <Link className="th-btn style2" to="/consultation">
                    Build Your Dream Now{" "}
                    <i className="fas fa-arrow-right ms-1" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-2">
              <div className="wcu-img-1">
                <div className="img1">
                  <img src="assets/img/normal/wcu_1_1.png" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="overflow-hidden space" id="service-sec">
        <div className="container">
          <div className="title-area text-center">
            <span className="sub-title2">
              <img
                className="me-1"
                src="assets/img/icon/subtitle-img2-1.svg"
                alt="img"
              />
              Our Services
            </span>
            <h2 className="sec-title">The Best Services For You</h2>
          </div>
          <div className="row gy-4">
            <div className="col-md-6 col-lg-4">
              <div className="service-card style2 bg-shadow">
                <div className="service-card-bg-img ser" />
                <div className="service-content">
                  <div className="service-card-icon">
                    <img
                      src="assets/img/icon/service-icon-2-1.svg"
                      alt="icon"
                    />
                  </div>
                  <h3 className="box-title">
                    <Link to="/engineering">Engineering</Link>
                  </h3>
                  <div className="service-card-number">01</div>
                </div>
                <p className="service-card-text">
                  Offer technical design, development, and consulting for
                  projects in various fields such as mechanical, electrical, and
                  civil engineering. These services can help clients in design
                  optimization, prototyping, testing, and product launch.
                </p>
                <Link className="th-btn style10" to="/engineering">
                  Read More <i className="fas fa-arrow-right ms-1" />
                </Link>
                <div className="service-card-shape">
                  <img
                    src="assets/img/service/service-card-bg-2-1.png"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="service-card style2 bg-shadow">
                <div className="service-card-bg-img ser" />
                <div className="service-content">
                  <div className="service-card-icon">
                    <img
                      src="assets/img/icon/service-icon-2-4.svg"
                      alt="icon"
                    />
                  </div>
                  <h3 className="box-title">
                    <Link to="/project-management">Project Management</Link>
                  </h3>
                  <div className="service-card-number">02</div>
                </div>
                <p className="service-card-text">
                  Involves overseeing the planning, implementation, and tracking
                  of a specific short-term project. It involves coordinating the
                  efforts of team members to ensure that all aspects of the
                  project are completed on time and within budget.
                </p>
                <Link className="th-btn style10" to="/project-management">
                  Read More <i className="fas fa-arrow-right ms-1" />
                </Link>
                <div className="service-card-shape">
                  <img
                    src="assets/img/service/service-card-bg-2-1.png"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="service-card style2 bg-shadow">
                <div className="service-card-bg-img ser" />
                <div className="service-content">
                  <div className="service-card-icon">
                    <img
                      src="assets/img/icon/service-icon-1-3.svg"
                      alt="icon"
                    />
                  </div>
                  <h3 className="box-title">
                    <Link to="/field-services">Field Services</Link>
                  </h3>
                  <div className="service-card-number">03</div>
                </div>
                <p className="service-card-text">
                  This service involves on-site work and maintenance of
                  products, structures, and systems. This includes installation,
                  repair, and maintenance services, as well as testing and
                  inspection services.
                </p>
                <Link className="th-btn style10" to="/field-services">
                  Read More <i className="fas fa-arrow-right ms-1" />
                </Link>
                <div className="service-card-shape">
                  <img
                    src="assets/img/service/service-card-bg-2-1.png"
                    alt="img"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="service-card style2 bg-shadow">
                <div className="service-card-bg-img ser" />
                <div className="service-content">
                  <div className="service-card-icon">
                    <img
                      src="assets/img/icon/service-icon-1-1.svg"
                      alt="icon"
                    />
                  </div>
                  <h3 className="box-title">
                    <Link to="/staffing-support">Staffing Support</Link>
                  </h3>
                  <div className="service-card-number">04</div>
                </div>
                <p className="service-card-text">
                  This service provides staffing solutions for businesses and
                  organizations. This includes finding, recruiting, and hiring
                  employees, as well as providing support for human resource and
                  payroll management.
                </p>
                <Link className="th-btn style10" to="/staffing-support">
                  Read More <i className="fas fa-arrow-right ms-1" />
                </Link>
                <div className="service-card-shape">
                  <img
                    src="assets/img/service/service-card-bg-2-1.png"
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="team-area overflow-hidden space-top"
        id="team-sec"
        data-bg-src="assets/img/bg/team_bg_1.png"
        data-overlay="title"
      >
        <div className="container"></div>
      </div>

      <div className="container" id="contact-sec">
        <div className="cta-area-1">
          <div className="row g-0 align-items-center">
            <div className="col-xl-5">
              <div className="cta-wrap title-area mb-0 bg-theme">
                <span className="sub-title text-white">Get a Quote</span>
                <h2 className="sec-title text-white">
                  Have An Upcoming Project? Let’s Talk!
                </h2>
                <p className="sec-text text-white">
                  Send us details about your project and we'll respond back as
                  soon as possible.
                </p>
              </div>
            </div>
            <div className="col-xl-7">
              <div className="contact-form-wrap">
                <h2 className="title h4 text-center">Request A Quote</h2>
                <form
                  onSubmit={sendMessage}
                  className="contact-form ajax-contact"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          placeholder="Your Name*"
                          required
                          value={formData.name}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />{" "}
                        <i className="fal fa-user" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Email Address*"
                          required
                          value={formData.email}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />{" "}
                        <i className="fal fa-envelope" />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="tel"
                          className="form-control"
                          name="phone"
                          id="number"
                          placeholder="Phone Number*"
                          required
                          value={formData.phone}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />{" "}
                        <i className="fal fa-phone" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="service"
                          id="service"
                          placeholder="Interest of Service*"
                          required
                          value={formData.service}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />{" "}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <textarea
                          name="message"
                          id="message"
                          cols={30}
                          rows={3}
                          className="form-control"
                          placeholder="Write Your Message*"
                          required
                          value={formData.message}
                          onChange={(e) => {
                            inputChange(e);
                          }}
                        />{" "}
                        <i className="fal fa-pen" />
                      </div>
                    </div>
                    <div className="form-btn col-12">
                      {loading ? (
                        <button className="th-btn w-100" disabled type="button">
                          Sending...
                        </button>
                      ) : (
                        <button className="th-btn w-100" type="submit">
                          Send
                          <i className="fas fa-long-arrow-right ms-2" />
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ClientSlider />

      <Footer />
      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </>
  );
};

export default Home;
