import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo-dark.png";
import logoWhite from "../../assets/logo.png";
import Footer from "../../components/Footer";
import pipeline from "../../assets/pipeline.jpg";

const FieldServices = () => {
  const [sidemenuOpen, setSideMenuOpen] = useState(false);
  const [mobilemenuOpen, setMobileMenuOpen] = useState(false);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [fieldServicesDropdownOpen, setFieldServicesDropdownOpen] =
    useState(false);

  const toggleSideMenu = () => {
    setSideMenuOpen(!sidemenuOpen);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobilemenuOpen);
  };

  const toggleServiceDropdown = () => {
    setServiceDropdownOpen(!serviceDropdownOpen);
  };

  const toggleFieldServicesDropdown = () => {
    setFieldServicesDropdownOpen(!fieldServicesDropdownOpen);
  };

  useEffect(() => {
    const urlHash = window.location.hash;
    if (urlHash.length) {
      const element = document.getElementById(urlHash.substring(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);

  return (
    <>
      <div
        className={`${
          sidemenuOpen
            ? "sidemenu-wrapper d-none d-lg-block show"
            : "sidemenu-wrapper d-none d-lg-block"
        }`}
      >
        <div className="sidemenu-content sidemenu-area">
          <button className="closeButton sideMenuCls" onClick={toggleSideMenu}>
            <i className="far fa-times" />
          </button>
          <div className="widget">
            <div className="th-widget-about">
              <div className="about-logo">
                <Link to="/">
                  <img src={logo} alt="logo" style={{ width: "300px" }} />
                </Link>
              </div>
              <p className="about-text">
                Quality-Driven Engineering and Project Management Solutions
              </p>
              <div className="th-social style2">
                <h6 className="title">FOLLOW US ON:</h6>
                <a href="#/">
                  <i className="fab fa-facebook-f" />
                </a>{" "}
                <a href="#/">
                  <i className="fab fa-instagram" />
                </a>
                <a href="#/">
                  <i className="fab fa-twitter" />
                </a>{" "}
                <a
                  href="https://www.linkedin.com/company/michaelsandassociates/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in" />
                </a>{" "}
              </div>
            </div>
          </div>
          <div className="widget widget_contact">
            <h3 className="widget_title">Get in touch!</h3>
            <div className="th-widget-contact">
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-location-dot" />
                </div>
                <p className="info-box_text">
                  12333 Sowden Rd Ste B, Houston, TX 77080-2059
                </p>
              </div>
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-envelope" />
                </div>
                <a
                  href="mailto:support@michaelsandassociate.com"
                  className="info-box_link"
                >
                  support@michaelsandassociate.com
                </a>
              </div>
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-phone" />
                </div>
                <a href="tel:8327865397" className="info-box_link">
                  (832) 786-5397
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          mobilemenuOpen ? "th-menu-wrapper th-body-visible" : "th-menu-wrapper"
        }`}
      >
        <div className="th-menu-area text-center">
          <button className="th-menu-toggle" onClick={toggleMobileMenu}>
            <i className="fal fa-times" />
          </button>
          <div className="mobile-logo">
            <Link to="/">
              <img src={logo} alt="logo" style={{ width: "180px" }} />
            </Link>
          </div>
          <div className="th-mobile-menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li
                className={`${
                  serviceDropdownOpen
                    ? "menu-item-has-children th-item-has-children th-active"
                    : "menu-item-has-children th-item-has-children"
                }`}
              >
                <a href="#/" onClick={toggleServiceDropdown}>
                  Services & Solutions <span className="th-mean-expand" />
                </a>
                <ul
                  className={`${
                    serviceDropdownOpen
                      ? "sub-menu th-submenu th-open"
                      : "sub-menu th-submenu"
                  }`}
                  style={!serviceDropdownOpen ? { display: "none" } : {}}
                >
                  <li>
                    <Link to="/project-management">Project Management</Link>
                  </li>
                  <li>
                    <Link to="/engineering">Engineering</Link>
                  </li>
                  <li
                    className={`${
                      fieldServicesDropdownOpen ? "th-active" : ""
                    }`}
                  >
                    <a href="#/" onClick={toggleFieldServicesDropdown}>
                      Field Services <span className="th-mean-expand" />
                    </a>
                    <ul
                      className="mb-4"
                      style={
                        !fieldServicesDropdownOpen ? { display: "none" } : {}
                      }
                    >
                      <li>
                        <Link to="/field-services#asset">
                          Asset Integrity & Corrosion Management
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#inspection">Inspection</Link>
                      </li>
                      <li>
                        <Link to="/field-services#material">
                          Material Verification & Records Management
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#oilfield">
                          Oilfield Supervision
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#construction">
                          Construction Management
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/staffing-support">Staffing Support</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header className="th-header header-layout-default">
        <div className="header-top">
          <div className="container th-container">
            <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
              <div className="col-auto d-none d-lg-block">
                <div className="header-links">
                  <ul>
                    <li>
                      <i className="far fa-phone" />
                      <a href="tel:8327865397">(832) 786-5397</a>
                    </li>
                    <li className="d-none d-xl-inline-block">
                      <i className="far fa-envelope" />
                      <a href="mailto:support@michaelsandassociate.com">
                        support@michaelsandassociate.com
                      </a>
                    </li>
                    <li>
                      <i className="far fa-location-dot" />
                      12333 Sowden Rd Ste B, Houston, TX 77080-2059
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-auto">
                <div className="header-links header-right">
                  <ul>
                    <li>
                      <div className="header-social">
                        <span className="social-title">Follow Us:</span>{" "}
                        <a href="#/">
                          <i className="fab fa-facebook-f" />
                        </a>{" "}
                        <a href="#/">
                          <i className="fab fa-instagram" />
                        </a>{" "}
                        <a href="#/">
                          <i className="fab fa-twitter" />
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/company/michaelsandassociates/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in" />
                        </a>{" "}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-wrapper">
          <div className="menu-area">
            <div className="container th-container">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <div className="header-logo">
                    <Link to="/">
                      <img
                        src={logoWhite}
                        alt="logo"
                        style={{ width: "180px" }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-auto">
                  <nav className="main-menu d-none d-lg-inline-block">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="/services">Services & Solutions</Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/project-management">
                              Project Management
                            </Link>
                          </li>
                          <li>
                            <Link to="/engineering">Engineering</Link>
                          </li>
                          <li className="menu-item-has-children">
                            <Link to="/field-services">Field Services</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/field-services#asset">
                                  Asset Integrity & Corrosion Management
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#inspection">
                                  Inspection
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#material">
                                  Material Verification & Records Management
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#oilfield">
                                  Oilfield Supervision
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#construction">
                                  Construction Management
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/staffing-support">Staffing Support</Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </nav>
                  <button
                    type="button"
                    className="th-menu-toggle d-block d-lg-none"
                    onClick={toggleMobileMenu}
                  >
                    <i className="far fa-bars" />
                  </button>
                </div>
                <div className="col-auto d-none d-xl-block">
                  <div className="row">
                    <div className="col-auto">
                      <div className="header-button">
                        <button
                          type="button"
                          className="icon-btn sideMenuToggler"
                          onClick={toggleSideMenu}
                        >
                          <i className="fas fa-grid" />
                        </button>{" "}
                        <Link to="/consultation" className="th-btn ml-20">
                          GET A QUOTE <i className="fas fa-arrow-right ms-1" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="logo-bg lbg" />
          </div>
        </div>
      </header>
      <div className="breadcumb-wrapper wrap">
        <div className="container">
          <div className="breadcumb-content">
            <h1 className="breadcumb-title">Field Services</h1>
            <ul className="breadcumb-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Field Services</li>
            </ul>
          </div>
        </div>
      </div>
      <section className="space-top space-extra2-bottom" id="asset">
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-lg-8">
              <div className="page-single">
                <div className="page-img mb-30">
                  <img src={pipeline} alt="pipeline" />
                </div>
                <div className="page-content">
                  <h2 className="h3 page-title">
                    Asset Integrity & Corrosion Management
                  </h2>
                  <p className="mb-10">
                    Ensuring long-term asset performance is crucial to
                    maximizing value, profitability, and returns on investment.
                    Another crucial aspect is adhering to increasingly stringent
                    regulatory requirements. Working with a trusted, experienced
                    partner can help you efficiently navigate these challenges
                    and take advantage of new and emerging opportunities for
                    asset optimization.
                  </p>
                  <p className="mb-10">
                    For years, clients have trusted Michaels & Associates to
                    increase safety, improve reliability, reduce risk, and
                    extend the productive life of their assets—all within
                    regulatory parameters. Whether you want to create a new
                    asset or need to improve existing operations, we can
                    collaborate on a fit-for-purpose, comprehensive integrity
                    and corrosion solution that meets your objectives.
                  </p>
                  <p className="mb-10">
                    In each project, our dedicated professionals integrate
                    proven proprietary asset integrity and corrosion processes,
                    advanced technology, and extensive expertise in critical
                    infrastructure like pipelines, facilities, and storage. As a
                    result, you receive data-driven insights that maximize
                    operational efficiency, asset value, and profitability while
                    remaining compliant to regulatory requirements and
                    minimizing risk.
                  </p>
                  <p className="mb-10">Our solutions are centered around:</p>
                  <ul>
                    <li>Regulatory Compliance</li>
                    <ul>
                      <li>Jurisdictional Analysis and Determination</li>
                      <li>
                        Operations & Maintenance (O&M) Manual Programs per 49
                        CFR Parts 192 and 195.
                      </li>
                      <li>Compliance Program Development</li>
                      <li>Pipeline and Storage Safety Audit Support</li>
                    </ul>
                    <li>Integrity Management</li>
                    <ul>
                      <li>Written Programs per 49 CFR Parts 192 and 195</li>
                      <li>Gap Analysis for Existing Programs</li>
                      <li>
                        Maximum Operating Pressure (MOP) & Maximum-Allowable
                        Operating Pressure (MAOP) Verification Programs
                      </li>
                      <li>
                        High-Consequence Area (HCA) & Class Location Analysis
                      </li>
                      <li>
                        Emergency Flow-Restricting Device (EFRD) &
                        Leak-Detection Capability Analysis
                      </li>
                      <li>Preventative & Mitigative Measures Programs</li>
                      <li id="inspection">
                        Risk Analysis and Prioritization, Assessment Programs
                      </li>
                      <li>Engineering Critical Analysis (ECA)</li>
                    </ul>
                    <li>Operations & Maintenance</li>
                    <ul>
                      <li>Regulatory Report Filing</li>
                      <li>Field Inspection & Data Gathering</li>
                      <li>GIS Database Record Management</li>
                      <li>Compliance Program Management</li>
                    </ul>
                  </ul>

                  <h2 className="h3 page-title">Inspection</h2>
                  <p className="mb-10">
                    Frequent inspection and maintenance of pipelines,
                    facilities, and other critical infrastructure assets play an
                    important part in maintaining key infrastructure and
                    ensuring operational safety across the asset lifecycle.
                    Expert inspection services help mitigate risk, improve
                    process safety and HSE performance, keep operations running
                    efficiently, and maximize profitability.
                  </p>
                  <p className="mb-10">
                    For years, Michaels & Associates has gained hands-on
                    experience and deep domain expertise in pipeline and
                    facilities inspections. Our comprehensive range of
                    inspection services boost efficiency while combatting the
                    challenges associated with asset production, construction,
                    manufacturing, maintenance, corrosion, logistics,
                    commissioning, and beyond—no matter the size, scope, or
                    breadth of your project.
                  </p>
                  <p className="mb-10">
                    Our expert inspectors have decades of technical experience
                    in construction and industrial operations. Each inspection
                    professional is trained in the latest inspection processes,
                    mobile technologies, industry codes and standards, and
                    government regulations. As a result, we deliver data-driven
                    inspection solutions that ensure the safety, compliance,
                    availability, and reliability of your asset to maximize its
                    value and your return on investment.
                  </p>
                  <p className="mb-10">Our Inspection Personnel include:</p>
                  <ul id="material">
                    <li>Field Project Managers</li>
                    <li>Construction Managers</li>
                    <li>Chief Inspectors</li>
                    <li>Certified Welding Inspectors</li>
                    <li>Utility Inspectors</li>
                    <li>Civil Inspectors</li>
                    <li>Electrical Inspectors</li>
                    <li>NACE Level I, II & III</li>
                    <li>Tank Inspectors</li>
                    <li>Environmental Inspectors</li>
                    <li>Oil field Inspectors</li>
                  </ul>

                  <h2 className="h3 page-title" id="oilfield">
                    Material Verification & Records Management
                  </h2>
                  <p className="mb-10" id="construction">
                    Our team offers material verification and records management
                    services to ensure that our clients have accurate and
                    up-to-date information on their assets. We utilize the
                    latest technologies and methods to perform material
                    verification, as well as maintain and update asset
                    information in electronic records management systems. Our
                    goal is to ensure that our clients have the information they
                    need to make informed decisions about their assets.
                  </p>

                  <h2 className="h3 page-title">Oilfield Supervision</h2>
                  <p className="mb-10">
                    We offer oilfield supervision services to our clients,
                    ensuring that their operations run safely and efficiently.
                    Our team has extensive experience in oilfield operations and
                    is familiar with the latest technologies and practices. We
                    provide our clients with on-site supervision, as well as
                    remote monitoring and reporting services, to help them
                    maintain optimal performance.
                  </p>

                  <h2 className="h3 page-title">Construction Management</h2>
                  <p className="mb-10">
                    Our construction management services are designed to help
                    our clients complete their projects on time and within
                    budget. Our team has extensive experience in construction
                    management, and we utilize the latest technologies and
                    methods to ensure that our clients’ projects are completed
                    to their specifications. We work closely with our clients to
                    understand their goals and objectives, and we provide
                    ongoing support throughout the construction process.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-lg-4">
              <aside className="sidebar-area">
                <div className="widget widget_download">
                  <h4 className="widget_title">About Us</h4>
                  <div className="donwload-media-wrap">
                    <div className="download-media">
                      <div className="download-media_icon">
                        <i className="fal fa-file-lines" />
                      </div>
                      <div className="download-media_info">
                        <h5 className="download-media_title">
                          Company Details
                        </h5>
                      </div>
                      <Link to="/about" className="download-media_btn">
                        <i className="far fa-arrow-right" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="widget widget_banner ban"
                  data-overlay="title"
                  data-opacity={8}
                >
                  <div className="widget-banner">
                    <h4 className="title">
                      Need Help? We Are Here To Help You
                    </h4>
                    <a href="tel:8327865397" className="link">
                      (832) 786-5397
                    </a>{" "}
                    <Link to="/contact" className="th-btn style3">
                      Contact Us Now <i className="far fa-arrow-right ms-1" />
                    </Link>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default FieldServices;
