import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo-dark.png";
import logoWhite from "../../assets/logo.png";
import Footer from "../../components/Footer";

const StaffingSupport = () => {
  const [sidemenuOpen, setSideMenuOpen] = useState(false);
  const [mobilemenuOpen, setMobileMenuOpen] = useState(false);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [fieldServicesDropdownOpen, setFieldServicesDropdownOpen] =
    useState(false);

  const toggleSideMenu = () => {
    setSideMenuOpen(!sidemenuOpen);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobilemenuOpen);
  };

  const toggleServiceDropdown = () => {
    setServiceDropdownOpen(!serviceDropdownOpen);
  };

  const toggleFieldServicesDropdown = () => {
    setFieldServicesDropdownOpen(!fieldServicesDropdownOpen);
  };

  return (
    <>
      <div
        className={`${
          sidemenuOpen
            ? "sidemenu-wrapper d-none d-lg-block show"
            : "sidemenu-wrapper d-none d-lg-block"
        }`}
      >
        <div className="sidemenu-content sidemenu-area">
          <button className="closeButton sideMenuCls" onClick={toggleSideMenu}>
            <i className="far fa-times" />
          </button>
          <div className="widget">
            <div className="th-widget-about">
              <div className="about-logo">
                <Link to="/">
                  <img src={logo} alt="logo" style={{ width: "300px" }} />
                </Link>
              </div>
              <p className="about-text">
                Quality-Driven Engineering and Project Management Solutions
              </p>
              <div className="th-social style2">
                <h6 className="title">FOLLOW US ON:</h6>
                <a href="#/">
                  <i className="fab fa-facebook-f" />
                </a>{" "}
                <a href="#/">
                  <i className="fab fa-instagram" />
                </a>
                <a href="#/">
                  <i className="fab fa-twitter" />
                </a>{" "}
                <a
                  href="https://www.linkedin.com/company/michaelsandassociates/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in" />
                </a>{" "}
              </div>
            </div>
          </div>
          <div className="widget widget_contact">
            <h3 className="widget_title">Get in touch!</h3>
            <div className="th-widget-contact">
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-location-dot" />
                </div>
                <p className="info-box_text">
                  12333 Sowden Rd Ste B, Houston, TX 77080-2059
                </p>
              </div>
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-envelope" />
                </div>
                <a
                  href="mailto:support@michaelsandassociate.com"
                  className="info-box_link"
                >
                  support@michaelsandassociate.com
                </a>
              </div>
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-phone" />
                </div>
                <a href="tel:8327865397" className="info-box_link">
                  (832) 786-5397
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          mobilemenuOpen ? "th-menu-wrapper th-body-visible" : "th-menu-wrapper"
        }`}
      >
        <div className="th-menu-area text-center">
          <button className="th-menu-toggle" onClick={toggleMobileMenu}>
            <i className="fal fa-times" />
          </button>
          <div className="mobile-logo">
            <Link to="/">
              <img src={logo} alt="logo" style={{ width: "180px" }} />
            </Link>
          </div>
          <div className="th-mobile-menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li
                className={`${
                  serviceDropdownOpen
                    ? "menu-item-has-children th-item-has-children th-active"
                    : "menu-item-has-children th-item-has-children"
                }`}
              >
                <a href="#/" onClick={toggleServiceDropdown}>
                  Services & Solutions <span className="th-mean-expand" />
                </a>
                <ul
                  className={`${
                    serviceDropdownOpen
                      ? "sub-menu th-submenu th-open"
                      : "sub-menu th-submenu"
                  }`}
                  style={!serviceDropdownOpen ? { display: "none" } : {}}
                >
                  <li>
                    <Link to="/project-management">Project Management</Link>
                  </li>
                  <li>
                    <Link to="/engineering">Engineering</Link>
                  </li>
                  <li
                    className={`${
                      fieldServicesDropdownOpen ? "th-active" : ""
                    }`}
                  >
                    <a href="#/" onClick={toggleFieldServicesDropdown}>
                      Field Services <span className="th-mean-expand" />
                    </a>
                    <ul
                      className="mb-4"
                      style={
                        !fieldServicesDropdownOpen ? { display: "none" } : {}
                      }
                    >
                      <li>
                        <Link to="/field-services#asset">
                          Asset Integrity & Corrosion Management
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#inspection">Inspection</Link>
                      </li>
                      <li>
                        <Link to="/field-services#material">
                          Material Verification & Records Management
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#oilfield">
                          Oilfield Supervision
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#construction">
                          Construction Management
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/staffing-support">Staffing Support</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header className="th-header header-layout-default">
        <div className="header-top">
          <div className="container th-container">
            <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
              <div className="col-auto d-none d-lg-block">
                <div className="header-links">
                  <ul>
                    <li>
                      <i className="far fa-phone" />
                      <a href="tel:8327865397">(832) 786-5397</a>
                    </li>
                    <li className="d-none d-xl-inline-block">
                      <i className="far fa-envelope" />
                      <a href="mailto:support@michaelsandassociate.com">
                        support@michaelsandassociate.com
                      </a>
                    </li>
                    <li>
                      <i className="far fa-location-dot" />
                      12333 Sowden Rd Ste B, Houston, TX 77080-2059
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-auto">
                <div className="header-links header-right">
                  <ul>
                    <li>
                      <div className="header-social">
                        <span className="social-title">Follow Us:</span>{" "}
                        <a href="#/">
                          <i className="fab fa-facebook-f" />
                        </a>{" "}
                        <a href="#/">
                          <i className="fab fa-instagram" />
                        </a>{" "}
                        <a href="#/">
                          <i className="fab fa-twitter" />
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/company/michaelsandassociates/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in" />
                        </a>{" "}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-wrapper">
          <div className="menu-area">
            <div className="container th-container">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <div className="header-logo">
                    <Link to="/">
                      <img
                        src={logoWhite}
                        alt="logo"
                        style={{ width: "180px" }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-auto">
                  <nav className="main-menu d-none d-lg-inline-block">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="/services">Services & Solutions</Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/project-management">
                              Project Management
                            </Link>
                          </li>
                          <li>
                            <Link to="/engineering">Engineering</Link>
                          </li>
                          <li className="menu-item-has-children">
                            <Link to="/field-services">Field Services</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/field-services#asset">
                                  Asset Integrity & Corrosion Management
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#inspection">
                                  Inspection
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#material">
                                  Material Verification & Records Management
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#oilfield">
                                  Oilfield Supervision
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#construction">
                                  Construction Management
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/staffing-support">Staffing Support</Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </nav>
                  <button
                    type="button"
                    className="th-menu-toggle d-block d-lg-none"
                    onClick={toggleMobileMenu}
                  >
                    <i className="far fa-bars" />
                  </button>
                </div>
                <div className="col-auto d-none d-xl-block">
                  <div className="row">
                    <div className="col-auto">
                      <div className="header-button">
                        <button
                          type="button"
                          className="icon-btn sideMenuToggler"
                          onClick={toggleSideMenu}
                        >
                          <i className="fas fa-grid" />
                        </button>{" "}
                        <Link to="/consultation" className="th-btn ml-20">
                          GET A QUOTE <i className="fas fa-arrow-right ms-1" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="logo-bg lbg" />
          </div>
        </div>
      </header>
      <div className="breadcumb-wrapper wrap">
        <div className="container">
          <div className="breadcumb-content">
            <h1 className="breadcumb-title">Staffing Support</h1>
            <ul className="breadcumb-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Staffing Support</li>
            </ul>
          </div>
        </div>
      </div>
      <section className="space-top space-extra2-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-lg-8">
              <div className="page-single">
                <div className="page-img mb-30">
                  <img src="assets/img/blog/blog-4-3.jpg" alt="engineering" />
                </div>
                <div className="page-content">
                  <h2 className="h3 page-title">Staffing Support</h2>
                  <p className="mb-30">
                    Michaels & Associates provides leading global workforce
                    solutions that connect people to work opportunities that
                    enrich their lives, making a difference in the communities
                    in which we live and work and strengthening our relationship
                    with partners. We approach every opportunity with care and
                    determination. We focus on understanding our clients’
                    business, goals and needs so that we can consistently
                    present highly qualified candidates and smart solutions.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-4 col-lg-4">
              <aside className="sidebar-area">
                <div className="widget widget_download">
                  <h4 className="widget_title">About Us</h4>
                  <div className="donwload-media-wrap">
                    <div className="download-media">
                      <div className="download-media_icon">
                        <i className="fal fa-file-lines" />
                      </div>
                      <div className="download-media_info">
                        <h5 className="download-media_title">
                          Company Details
                        </h5>
                      </div>
                      <Link to="/about" className="download-media_btn">
                        <i className="far fa-arrow-right" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="widget widget_banner ban"
                  data-overlay="title"
                  data-opacity={8}
                >
                  <div className="widget-banner">
                    <h4 className="title">
                      Need Help? We Are Here To Help You
                    </h4>
                    <a href="tel:8327865397" className="link">
                      (832) 786-5397
                    </a>{" "}
                    <Link to="/contact" className="th-btn style3">
                      Contact Us Now <i className="far fa-arrow-right ms-1" />
                    </Link>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default StaffingSupport;
