import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import microsoft from "../assets/microsoft.svg";
import facebook from "../assets/facebook.svg";
import defense from "../assets/defense.svg";
import cali from "../assets/southern-california-gas-company.svg";
import tva from "../assets/tva.svg";
import pge from "../assets/pacific-gas-and-electric-company.svg";
import intel from "../assets/intel.svg";
import energy from "../assets/us-department-of-energy.svg";

const ClientSlider = () => {
  return (
    <div
      className="z-index-common py-5"
      data-pos-for=".testi-area-3"
      data-sec-pos="bottom-half"
    >
      <div className="client-area-3">
        <div className="container-fluid">
          <div
            className="row th-carousel"
            data-slide-show={8}
            data-lg-slide-show={7}
            data-md-slide-show={6}
            data-sm-slide-show={3}
            data-xs-slide-show={2}
          >
            <Slider
              autoplay
              responsive={[
                {
                  breakpoint: 1600,
                  settings: {
                    slidesToShow: 7,
                    centerPadding: 0,
                  },
                },
                {
                  breakpoint: 1400,
                  settings: {
                    slidesToShow: 7,
                    centerPadding: 0,
                  },
                },
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 7,
                    centerPadding: 0,
                  },
                },
                {
                  breakpoint: 992,
                  settings: {
                    slidesToShow: 6,
                    centerPadding: 0,
                  },
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 3,
                    centerPadding: 0,
                  },
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 2,
                    centerPadding: 0,
                  },
                },
              ]}
            >
              <div className="col-lg-auto text-center">
                <a
                  href="#/"
                  className="client-thumb"
                  style={{ height: "140px", display: "flex" }}
                >
                  <img
                    src={microsoft}
                    alt="microsoft"
                    style={{ width: "80%" }}
                  />
                </a>
              </div>
              <div className="col-lg-auto text-center">
                <a
                  href="#/"
                  className="client-thumb"
                  style={{ height: "140px", display: "flex" }}
                >
                  <img src={facebook} alt="facebook" style={{ width: "80%" }} />
                </a>
              </div>
              <div className="col-lg-auto text-center">
                <a
                  href="#/"
                  className="client-thumb"
                  style={{ height: "140px", display: "flex" }}
                >
                  <img src={defense} alt="defense" style={{ width: "80%" }} />
                </a>
              </div>
              <div className="col-lg-auto text-center">
                <a
                  href="#/"
                  className="client-thumb"
                  style={{ height: "140px", display: "flex" }}
                >
                  <img src={cali} alt="cali" style={{ width: "80%" }} />
                </a>
              </div>
              <div className="col-lg-auto text-center">
                <a
                  href="#/"
                  className="client-thumb"
                  style={{ height: "140px", display: "flex" }}
                >
                  <img src={tva} alt="tva" style={{ width: "80%" }} />
                </a>
              </div>
              <div className="col-lg-auto text-center">
                <a
                  href="#/"
                  className="client-thumb"
                  style={{ height: "140px", display: "flex" }}
                >
                  <img src={pge} alt="pge" style={{ width: "80%" }} />
                </a>
              </div>
              <div className="col-lg-auto text-center">
                <a
                  href="#/"
                  className="client-thumb"
                  style={{ height: "140px", display: "flex" }}
                >
                  <img src={intel} alt="intel" style={{ width: "80%" }} />
                </a>
              </div>
              <div className="col-lg-auto text-center">
                <a
                  href="#/"
                  className="client-thumb"
                  style={{ height: "140px", display: "flex" }}
                >
                  <img src={energy} alt="energy" style={{ width: "80%" }} />
                </a>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientSlider;
