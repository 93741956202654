import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo-dark.png";
import logoWhite from "../assets/logo.png";
import Footer from "../components/Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Consultation = () => {
  const [sidemenuOpen, setSideMenuOpen] = useState(false);
  const [mobilemenuOpen, setMobileMenuOpen] = useState(false);
  const [serviceDropdownOpen, setServiceDropdownOpen] = useState(false);
  const [fieldServicesDropdownOpen, setFieldServicesDropdownOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
    message: "",
  });

  const inputChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const toggleSideMenu = () => {
    setSideMenuOpen(!sidemenuOpen);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobilemenuOpen);
  };

  const toggleServiceDropdown = () => {
    setServiceDropdownOpen(!serviceDropdownOpen);
  };

  const toggleFieldServicesDropdown = () => {
    setFieldServicesDropdownOpen(!fieldServicesDropdownOpen);
  };

  const sendMessage = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      await axios.post(
        "https://michaelsandassociates.onrender.com/api/consultation",
        {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          service: formData.service,
          message: formData.message,
        }
      );

      toast.success("Message sent successfully!");
      setLoading(false);
      setFormData((prevState) => ({
        ...prevState,
        name: "",
        email: "",
        phone: "",
        service: "",
        message: "",
      }));
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <>
      <div
        className={`${
          sidemenuOpen
            ? "sidemenu-wrapper d-none d-lg-block show"
            : "sidemenu-wrapper d-none d-lg-block"
        }`}
      >
        <div className="sidemenu-content sidemenu-area">
          <button className="closeButton sideMenuCls" onClick={toggleSideMenu}>
            <i className="far fa-times" />
          </button>
          <div className="widget">
            <div className="th-widget-about">
              <div className="about-logo">
                <Link to="/">
                  <img src={logo} alt="logo" style={{ width: "300px" }} />
                </Link>
              </div>
              <p className="about-text">
                Quality-Driven Engineering and Project Management Solutions
              </p>
              <div className="th-social style2">
                <h6 className="title">FOLLOW US ON:</h6>
                <a href="#/">
                  <i className="fab fa-facebook-f" />
                </a>{" "}
                <a href="#/">
                  <i className="fab fa-instagram" />
                </a>
                <a href="#/">
                  <i className="fab fa-twitter" />
                </a>{" "}
                <a
                  href="https://www.linkedin.com/company/michaelsandassociates/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-linkedin-in" />
                </a>{" "}
              </div>
            </div>
          </div>
          <div className="widget widget_contact">
            <h3 className="widget_title">Get in touch!</h3>
            <div className="th-widget-contact">
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-location-dot" />
                </div>
                <p className="info-box_text">
                  12333 Sowden Rd Ste B, Houston, TX 77080-2059
                </p>
              </div>
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-envelope" />
                </div>
                <a
                  href="mailto:support@michaelsandassociate.com"
                  className="info-box_link"
                >
                  support@michaelsandassociate.com
                </a>
              </div>
              <div className="info-box-wrap">
                <div className="info-box_icon">
                  <i className="fas fa-phone" />
                </div>
                <a href="tel:8327865397" className="info-box_link">
                  (832) 786-5397
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          mobilemenuOpen ? "th-menu-wrapper th-body-visible" : "th-menu-wrapper"
        }`}
      >
        <div className="th-menu-area text-center">
          <button className="th-menu-toggle" onClick={toggleMobileMenu}>
            <i className="fal fa-times" />
          </button>
          <div className="mobile-logo">
            <Link to="/">
              <img src={logo} alt="logo" style={{ width: "180px" }} />
            </Link>
          </div>
          <div className="th-mobile-menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li
                className={`${
                  serviceDropdownOpen
                    ? "menu-item-has-children th-item-has-children th-active"
                    : "menu-item-has-children th-item-has-children"
                }`}
              >
                <a href="#/" onClick={toggleServiceDropdown}>
                  Services & Solutions <span className="th-mean-expand" />
                </a>
                <ul
                  className={`${
                    serviceDropdownOpen
                      ? "sub-menu th-submenu th-open"
                      : "sub-menu th-submenu"
                  }`}
                  style={!serviceDropdownOpen ? { display: "none" } : {}}
                >
                  <li>
                    <Link to="/project-management">Project Management</Link>
                  </li>
                  <li>
                    <Link to="/engineering">Engineering</Link>
                  </li>
                  <li
                    className={`${
                      fieldServicesDropdownOpen ? "th-active" : ""
                    }`}
                  >
                    <a href="#/" onClick={toggleFieldServicesDropdown}>
                      Field Services <span className="th-mean-expand" />
                    </a>
                    <ul
                      className="mb-4"
                      style={
                        !fieldServicesDropdownOpen ? { display: "none" } : {}
                      }
                    >
                      <li>
                        <Link to="/field-services#asset">
                          Asset Integrity & Corrosion Management
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#inspection">Inspection</Link>
                      </li>
                      <li>
                        <Link to="/field-services#material">
                          Material Verification & Records Management
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#oilfield">
                          Oilfield Supervision
                        </Link>
                      </li>
                      <li>
                        <Link to="/field-services#construction">
                          Construction Management
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/staffing-support">Staffing Support</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header className="th-header header-layout-default">
        <div className="header-top">
          <div className="container th-container">
            <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
              <div className="col-auto d-none d-lg-block">
                <div className="header-links">
                  <ul>
                    <li>
                      <i className="far fa-phone" />
                      <a href="tel:8327865397">(832) 786-5397</a>
                    </li>
                    <li className="d-none d-xl-inline-block">
                      <i className="far fa-envelope" />
                      <a href="mailto:support@michaelsandassociate.com">
                        support@michaelsandassociate.com
                      </a>
                    </li>
                    <li>
                      <i className="far fa-location-dot" />
                      12333 Sowden Rd Ste B, Houston, TX 77080-2059
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-auto">
                <div className="header-links header-right">
                  <ul>
                    <li>
                      <div className="header-social">
                        <span className="social-title">Follow Us:</span>{" "}
                        <a href="#/">
                          <i className="fab fa-facebook-f" />
                        </a>{" "}
                        <a href="#/">
                          <i className="fab fa-instagram" />
                        </a>{" "}
                        <a href="#/">
                          <i className="fab fa-twitter" />
                        </a>{" "}
                        <a
                          href="https://www.linkedin.com/company/michaelsandassociates/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in" />
                        </a>{" "}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sticky-wrapper">
          <div className="menu-area">
            <div className="container th-container">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <div className="header-logo">
                    <Link to="/">
                      <img
                        src={logoWhite}
                        alt="logo"
                        style={{ width: "180px" }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-auto">
                  <nav className="main-menu d-none d-lg-inline-block">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li className="menu-item-has-children">
                        <Link to="/services">Services & Solutions</Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/project-management">
                              Project Management
                            </Link>
                          </li>
                          <li>
                            <Link to="/engineering">Engineering</Link>
                          </li>
                          <li className="menu-item-has-children">
                            <Link to="/field-services">Field Services</Link>
                            <ul className="sub-menu">
                              <li>
                                <Link to="/field-services#asset">
                                  Asset Integrity & Corrosion Management
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#inspection">
                                  Inspection
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#material">
                                  Material Verification & Records Management
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#oilfield">
                                  Oilfield Supervision
                                </Link>
                              </li>
                              <li>
                                <Link to="/field-services#construction">
                                  Construction Management
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li>
                            <Link to="/staffing-support">Staffing Support</Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </nav>
                  <button
                    type="button"
                    className="th-menu-toggle d-block d-lg-none"
                    onClick={toggleMobileMenu}
                  >
                    <i className="far fa-bars" />
                  </button>
                </div>
                <div className="col-auto d-none d-xl-block">
                  <div className="row">
                    <div className="col-auto">
                      <div className="header-button">
                        <button
                          type="button"
                          className="icon-btn sideMenuToggler"
                          onClick={toggleSideMenu}
                        >
                          <i className="fas fa-grid" />
                        </button>{" "}
                        <Link to="/consultation" className="th-btn ml-20">
                          GET A QUOTE <i className="fas fa-arrow-right ms-1" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="logo-bg lbg" />
          </div>
        </div>
      </header>
      <div className="breadcumb-wrapper wrap">
        <div className="container">
          <div className="breadcumb-content">
            <h1 className="breadcumb-title">Consultation</h1>
            <ul className="breadcumb-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Consultation</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="contact-area-2 space-top" id="contact-sec">
        <div className="container">
          <div className="row gy-4 justify-content-center">
            <div className="col-lg-12">
              <div className="contact-wrap2">
                <div className="contact-form-wrap">
                  <h2 className="title h3 text-center mt-n1">Get a Quote</h2>
                  <form
                    onSubmit={sendMessage}
                    className="contact-form ajax-contact"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            id="name"
                            placeholder="Your Name*"
                            required
                            value={formData.name}
                            onChange={(e) => {
                              inputChange(e);
                            }}
                          />{" "}
                          <i className="fal fa-user" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="email"
                            placeholder="Email Address*"
                            required
                            value={formData.email}
                            onChange={(e) => {
                              inputChange(e);
                            }}
                          />{" "}
                          <i className="fal fa-envelope" />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="tel"
                            className="form-control"
                            name="phone"
                            id="number"
                            placeholder="Phone Number*"
                            required
                            value={formData.phone}
                            onChange={(e) => {
                              inputChange(e);
                            }}
                          />{" "}
                          <i className="fal fa-phone" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="service"
                            id="service"
                            placeholder="Interest of Service*"
                            required
                            value={formData.service}
                            onChange={(e) => {
                              inputChange(e);
                            }}
                          />{" "}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            id="message"
                            cols={30}
                            rows={3}
                            className="form-control"
                            placeholder="Write Your Message*"
                            required
                            value={formData.message}
                            onChange={(e) => {
                              inputChange(e);
                            }}
                          />{" "}
                          <i className="fal fa-pen" />
                        </div>
                      </div>
                      <div className="form-btn col-12">
                        {loading ? (
                          <button
                            className="th-btn w-100"
                            disabled
                            type="button"
                          >
                            Sending...
                          </button>
                        ) : (
                          <button className="th-btn w-100" type="submit">
                            Send
                            <i className="fas fa-long-arrow-right ms-2" />
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer
        autoClose={4000}
        style={{ fontFamily: "inherit" }}
        hideProgressBar
        theme="colored"
      />
    </>
  );
};

export default Consultation;
